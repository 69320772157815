import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import Layout from '../components/Layout';
import Image from '../images/Hero.png';
import SEO from '../components/Seo';

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="Home" keywords={[`paleo`, `health`, `wellness`, `coach`, `fitness`, `coaching`, `chef`, `mindfulness`, `desporto`, `gym`, `ginasio`]} />

    <div style={{
      backgroundImage: `url(${Image})`,
      backgroundSize: 'auto 380px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '90%',
      height: 380
    }}>
      <Grid>
        <Row>
          <Col md={6}>
            <div style={{
              fontFamily: 'Playfair Display',
              fontSize: 92,
              lineHeight: 1,
              fontWeight: 900,
              marginTop: 70
            }}>
              Na Caverna <br /> da Marta
            </div>
          </Col>
        </Row>
      </Grid>
    </div>

    <div style={{ clear: 'both' }} />

    <div style={{
      borderTop: '1px #ddd solid',
      width: 80,
      margin: '0 auto'
    }} />

    <div style={{
      fontFamily: 'Muli',
      fontSize: 48,
      lineHeight: 1,
      fontWeight: 700,
      textAlign: 'center',
      color: '#faab06',
      margin: '80px 0'
    }}>
      Esta página não existe.
    </div>
  </Layout>
)

export default NotFoundPage
